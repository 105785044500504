import { packageVersion } from './package-version';

/**
 * TODO: Remove once api_gateway is removed from environment
 */
const API_URL = 'https://backend.legalbot.cl/api/';

export const environment = {
  production: true,
  env: 'prod',
  version: packageVersion,
  api_gateways: {
    authSantander: `${API_URL}v2/login/`,
    verifyToken: `${API_URL}v2/token/verify/`,
    refreshToken: `${API_URL}v2/token/refresh/`,
    customerData: `${API_URL}v2/customers/data/`,
    study: `${API_URL}v2/studies/`,
  },
};
